import { omit } from 'lodash';
import { FETCH_POST_SUCCESS } from '../actions/fetch-post';
import { FETCH_POSTS_SUCCESS } from '../actions/fetch-posts';
import { FETCH_UPDATED_POSTS_SUCCESS } from '../actions/fetch-updated-posts';
import { FETCH_SEARCH_SUCCESS } from '../actions/fetch-search';
import { UPDATE_POST_SUCCESS } from '../actions/update-post';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { CREATE_POST_SUCCESS } from '../actions/create-post';
import { MOVE_POST_SUCCESS } from '../actions/move-post';
import { PIN_POST_SUCCESS } from '../actions/pin-post';
import { UNPIN_POST_SUCCESS } from '../actions/unpin-post';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../actions/enable-post-comments';
import { DISABLE_POST_COMMENTS_SUCCESS } from '../actions/disable-post-comments';
import { SUBSCRIBE_REQUEST, SUBSCRIBE_FAILURE } from '../actions/subscribe';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_FAILURE } from '../actions/unsubscribe';
import {
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
  INCREMENT_POST_LIKE_COUNT_FAILURE,
} from '../../common/actions/increment-post-like-count';
import { FETCH_POST_PAGE_DATA_SUCCESS } from '../actions/fetch-post-page-data';
import {
  MARK_POSTS_AS_READ_SUCCESS,
  MARK_POST_AS_READ_SUCCESS,
  MARK_POST_AS_UNREAD_SUCCESS,
} from '../containers/user-activity';
import {
  REACT_TO_POST_REQUEST,
  REACT_TO_POST_SUCCESS,
  REACT_TO_POST_FAILURE,
} from '../containers/post-reactions/post-reactions-actions';
import { fromServerReaction } from '../containers/post-reactions/reaction-converters';
import { postReactionsReducer } from '../containers/post-reactions/post-reactions-reducer';
import { isDefined } from '../../utils/ts-utils';
import { FETCH_FOOTER_POSTS_SUCCESS } from '../actions/fetch-footer-posts';

// eslint-disable-next-line complexity
const post = (state, action) => {
  switch (action.type) {
    case SUBSCRIBE_REQUEST:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: true };
      }
      return state;
    case SUBSCRIBE_FAILURE:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_REQUEST:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_FAILURE:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: true };
      }
      return state;
    case REACT_TO_POST_REQUEST:
    case REACT_TO_POST_SUCCESS:
    case REACT_TO_POST_FAILURE:
      return postReactionsReducer(state, action);
    case INCREMENT_POST_LIKE_COUNT_REQUEST:
      if (state._id === action.payload._id) {
        return {
          ...state,
          likes: state.isLiked
            ? state.likes.filter((l) => l.siteMemberId !== action.payload.currentUser.siteMemberId)
            : [action.payload.currentUser].concat(state.likes),
          isLiked: !state.isLiked,
          likeCount: state.likeCount + (state.isLiked ? -1 : 1),
          isLikeInProgress: true,
        };
      }
      return state;
    case INCREMENT_POST_LIKE_COUNT_SUCCESS:
      if (state._id === action.payload._id) {
        return omit({ ...state, ...action.payload }, 'isLikeInProgress');
      }
      return state;
    case INCREMENT_POST_LIKE_COUNT_FAILURE:
      if (state._id === action.payload._id) {
        return omit(
          {
            ...state,
            likes: state.isLiked
              ? state.likes.filter(
                  (l) => l.siteMemberId !== action.payload.currentUser.siteMemberId,
                )
              : [action.payload.currentUser].concat(state.likes),
            isLiked: !state.isLiked,
            likeCount: state.likeCount + (state.isLiked ? -1 : 1),
          },
          'isLikeInProgress',
        );
      }
      return state;
    default:
      return state;
  }
};

// eslint-disable-next-line complexity
export default (state = {}, { type, payload } = {}) => {
  switch (type) {
    case FETCH_POST_SUCCESS:
      return unionPosts(state, [payload.post]);
    case FETCH_POST_PAGE_DATA_SUCCESS:
      return unionPosts(state, [{ ...payload.result.post }]);
    case FETCH_UPDATED_POSTS_SUCCESS:
    case FETCH_POSTS_SUCCESS:
    case FETCH_FOOTER_POSTS_SUCCESS:
      return unionPosts(state, payload);
    case UPDATE_POST_SUCCESS:
    case MOVE_POST_SUCCESS:
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
      const postInStateHasIsRead = state[payload._id]?.isRead !== undefined;
      return unionPosts(state, [
        postInStateHasIsRead ? { ...payload, isRead: state[payload._id].isRead } : payload,
      ]);
    case CREATE_POST_SUCCESS:
      // isSubscribed: true, because new post is subscribed by default. https://app.asana.com/0/1201359624121121/1201587884715612/f
      return unionPosts(state, [{ ...payload, isSubscribed: true }]);
    case FETCH_SEARCH_SUCCESS:
      return unionPosts(
        state,
        payload.posts.filter((post) => post.type === 'post'),
      );
    case DELETE_POST_SUCCESS:
      return omit(state, payload);
    case SUBSCRIBE_REQUEST:
    case SUBSCRIBE_FAILURE:
    case UNSUBSCRIBE_REQUEST:
    case UNSUBSCRIBE_FAILURE:
      if (payload.type === 'post') {
        return Object.keys(state).reduce((result, current) => {
          result[current] = post(state[current], { type, payload });
          return result;
        }, {});
      }
      return state;
    case INCREMENT_POST_LIKE_COUNT_SUCCESS:
      return Object.keys(state).reduce((result, current) => {
        result[current] = post(state[current], { type, payload });
        return result;
      }, {});
    case INCREMENT_POST_LIKE_COUNT_FAILURE:
      return Object.keys(state).reduce((result, current) => {
        result[current] = post(state[current], { type, payload });
        return result;
      }, {});
    case INCREMENT_POST_LIKE_COUNT_REQUEST:
      return Object.keys(state).reduce((result, current) => {
        result[current] = post(state[current], { type, payload });
        return result;
      }, {});
    case REACT_TO_POST_REQUEST:
    case REACT_TO_POST_SUCCESS:
    case REACT_TO_POST_FAILURE:
      return Object.keys(state).reduce((result, current) => {
        result[current] = post(state[current], { type, payload });
        return result;
      }, {});
    case MARK_POSTS_AS_READ_SUCCESS:
      return Object.keys(state).reduce((result, current) => {
        const p = state[current];
        result[current] =
          p.categoryId === payload.categoryId || payload.categoryId === null
            ? { ...p, isRead: true }
            : p;
        return result;
      }, {});
    case MARK_POST_AS_READ_SUCCESS:
      return {
        ...state,
        [payload.postId]: {
          ...state[payload.postId],
          isRead: true,
        },
      };
    case MARK_POST_AS_UNREAD_SUCCESS:
      return {
        ...state,
        [payload.postId]: {
          ...state[payload.postId],
          isRead: false,
        },
      };
    default:
      return state;
  }
};

function unionPosts(oldPosts, newPosts) {
  return newPosts.reduce(
    (result, post) => {
      // In some cases corrupted content is created and Ricos fails to handle it
      // Added default values for Ricos content.
      if (!post.content.entityMap) {
        post.content.entityMap = {};
      }

      if (!post.content.blocks) {
        post.content.blocks = [];
      }

      const { reactedIdentities, recentReactions, ...remainingPost } = post;
      result[remainingPost._id] = {
        ...remainingPost,
        reactions: {
          type: 'IDLE',
          reactedIdentities: reactedIdentities?.map(fromServerReaction).filter(isDefined) ?? [],
          recentReactions: recentReactions ?? [],
        },
      };
      return result;
    },
    { ...oldPosts },
  );
}
