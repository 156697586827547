import { FETCH_USER_SUCCESS } from '../../actions/fetch-user';
import {
  FOLLOW_MEMBER_FAILURE,
  FOLLOW_MEMBER_REQUEST,
  FOLLOW_MEMBER_SUCCESS,
  UNFOLLOW_MEMBER_FAILURE,
  UNFOLLOW_MEMBER_REQUEST,
  UNFOLLOW_MEMBER_SUCCESS,
} from '../member-follow/member-follow-actions';
import { memberFollowReducer } from '../member-follow/member-follow-reducer';
import {
  FetchMembersListAction,
  FETCH_MEMBERS_LIST_FAILURE,
  FETCH_MEMBERS_LIST_REQUEST,
  FETCH_MEMBERS_LIST_SUCCESS,
} from './members-actions';
import { MembersInternalState } from './members-types';

export function membersReducer(
  state: MembersInternalState = {
    status: 'IDLE',
    membersCache: {},
    followedMembers: [],
  },
  action?: FetchMembersListAction,
): MembersInternalState | {} {
  switch (action?.type) {
    case FETCH_MEMBERS_LIST_REQUEST: {
      return { ...state, status: 'PENDING', membersCache: state?.membersCache || [] };
    }
    case FETCH_MEMBERS_LIST_SUCCESS: {
      return {
        ...state,
        status: 'IDLE',
        membersCache: { ...state.membersCache, ...action.payload.members },
        followedMembers: action.payload.followedMembers.map((id: string) => ({
          id,
          status: 'FOLLOWED',
        })),
      };
    }
    case FETCH_MEMBERS_LIST_FAILURE: {
      return { ...state, status: 'IDLE', membersCache: state?.membersCache || [] };
    }
    case FETCH_USER_SUCCESS: {
      if (action.payload === undefined) {
        return state;
      }

      const siteMemberId = action.payload?.userId ?? action.payload?.siteMemberId;

      if (siteMemberId === undefined) {
        return state;
      }

      return {
        status: 'IDLE',
        currentUserId: siteMemberId,
        membersCache: {
          ...state.membersCache,
          [siteMemberId]: { ...action.payload, siteMemberId },
        },
        followedMembers:
          action.payload?.followedMembers?.map((id: string) => ({ id, status: 'FOLLOWED' })) ?? [],
      };
    }
    case FOLLOW_MEMBER_FAILURE:
    case FOLLOW_MEMBER_REQUEST:
    case FOLLOW_MEMBER_SUCCESS:
    case UNFOLLOW_MEMBER_FAILURE:
    case UNFOLLOW_MEMBER_REQUEST:
    case UNFOLLOW_MEMBER_SUCCESS:
      return memberFollowReducer(state, action);
    default:
      return state || {};
  }
}
