import { createAction } from '@reduxjs/toolkit';
import { ReactionCode } from '@wix/comments-ooi-client/reaction-types';

export const DELETE_CATEGORY = 'userEvents/DELETE_CATEGORY';
export const SHOW_EMPTY_STATE = 'userEvents/SHOW_EMPTY_STATE';
export const MOVE_POST = 'userEvents/MOVE_POST';
export const CLICK_PUBLISH = 'userEvents/CLICK_PUBLISH';
export const CLICK_LIKE = 'userEvents/CLICK_LIKE';
export const CLICK_REACTION = 'userEvents/CLICK_REACTION';
export const OPEN_REACTION_LIST = 'userEvents/OPEN_REACTION_LIST';
export const CLICK_PROFILE_LINK = 'userEvents/CLICK_PROFILE_LINK';
export const CLICK_CREATE_POST = 'userEvents/CLICK_CREATE_POST';
export const CLICK_CREATE_POST_BUTTON = 'userEvents/CLICK_CREATE_POST_BUTTON';
export const CLICK_CREATE_POST_BUTTON_NO_CATEGORY =
  'userEvents/CLICK_CREATE_POST_BUTTON_NO_CATEGORY';
export const SELECT_CATEGORY_ON_CREATE_POST = 'userEvents/SELECT_CATEGORY_ON_CREATE_POST';
export const POST_CAPTCHA_SHOWN = 'userEvents/POST_CAPTCHA_SHOWN';
export const POST_CAPTCHA_RESOLVED = 'userEvents/POST_CAPTCHA_RESOLVED';
export const COMMENT_CAPTCHA_SHOWN = 'userEvents/COMMENT_CAPTCHA_SHOWN';
export const COMMENT_CAPTCHA_RESOLVED = 'userEvents/COMMENT_CAPTCHA_RESOLVED';
export const GUIDELINES_SHOWN = 'userEvents/GUIDELINES_SHOWN';
export const POSTING_DISABLED_SHOWN = 'userEvents/POSTING_DISABLED_SHOWN';
export const POSTING_DISABLED_CLOSE = 'userEvents/POSTING_DISABLED_CLOSE';
export const SEARCH_FOCUSED = 'userEvents/SEARCH_FOCUSED';
export const SEARCH_CLEARED = 'userEvents/SEARCH_CLEARED';
export const SEARCH_SUGGESTIONS_LOADED = 'userEvents/SEARCH_SUGGESTIONS_LOADED';
export const SEARCH_SUBMITTED = 'userEvents/SEARCH_SUBMITTED';

export const userEventsDeleteCategory = createAction(DELETE_CATEGORY);
export const userEventsShowEmptyState = createAction<ShowEmptyState>(SHOW_EMPTY_STATE);
export const userEventsMovePost = createAction(MOVE_POST);
export const userEventsClickPublish = createAction<ClickPublish>(CLICK_PUBLISH);
export const userEventsClickLike = createAction<ClickLike>(CLICK_LIKE);
export const userEventsClickReaction = createAction<ClickReaction>(CLICK_REACTION);
export const userEventsOpenReactionList = createAction<OpenReactionList>(OPEN_REACTION_LIST);
export const userEventsClickProfileLink = createAction<ClickProfileLink>(CLICK_PROFILE_LINK);
export const userEventsClickCreatePost = createAction<string>(CLICK_CREATE_POST);
export const userEventsClickCreatePostButton = createAction(CLICK_CREATE_POST_BUTTON);
export const userEventsClickCreatePostButtonNoCategory = createAction<CreatePostButtonNoCategory>(
  CLICK_CREATE_POST_BUTTON_NO_CATEGORY,
);
export const userEventsSelectCategoryOnCreatePost = createAction<SelectCategoryOnCreatePost>(
  SELECT_CATEGORY_ON_CREATE_POST,
);
export const userEventsPostCaptchaShown = createAction(POST_CAPTCHA_SHOWN);
export const userEventsPostCaptchaResolved = createAction<boolean>(POST_CAPTCHA_RESOLVED);
export const userEventsCommentCaptchaShown = createAction(COMMENT_CAPTCHA_SHOWN);
export const userEventsCommentCaptchaResolved = createAction<boolean>(COMMENT_CAPTCHA_RESOLVED);
export const userEventsGuidelinesShown = createAction<GuidelinesShown>(GUIDELINES_SHOWN);
export const userEventsPostingDisabledShown = createAction<boolean>(POSTING_DISABLED_SHOWN);
export const userEventsPostingDisabledClose = createAction(POSTING_DISABLED_CLOSE);
export const userEventsSearchFocused = createAction(SEARCH_FOCUSED);
export const userEventsSearchCleared = createAction(SEARCH_CLEARED);
export const userEventsSearchSuggestionsLoaded = createAction<string>(SEARCH_SUGGESTIONS_LOADED);
export const userEventsSearchSubmitted = createAction<SearchSubmitted>(SEARCH_SUBMITTED);

interface ClickProfileLink {
  isOwnProfile: boolean;
}

interface SelectCategoryOnCreatePost {
  id: string;
  hasTriedSubmitting: boolean;
}

interface ShowEmptyState {
  type: string;
  categoryId: string;
}

interface ClickLike {
  _id: string;
  type: string;
  isLiked: boolean;
}

interface ClickReaction {
  subaction: 'REMOVE' | 'REPLACE' | 'ADD';
  postId: string;
  reactionCode: ReactionCode;
  type: string;
}

interface OpenReactionList {
  type: 'likes' | 'emotions';
  postId: string;
}

interface ClickPublish {
  postId?: string;
  type: string;
  symbolCount: number;
  isEditing: boolean;
}

interface ReplyOrCommentIntent {
  type: string;
  origin?: string;
}

interface GuidelinesShown {
  type: string;
  origin: string;
  categoryId: string;
}

interface SearchSubmitted {
  query: string;
  location: string;
}

interface CreatePostButtonNoCategory {
  postTitle: string;
}
